
import React, { useState } from 'react';
import { FaArrowRight, FaCheck } from 'react-icons/fa'; // Import icons

import tokcommentHeader from '../assets/images/tokcomment-header.png';
import tokcommentLogo from '../assets/images/tokcomment-logo.png';


import EmailIcon from './EmailIcon';

const Header = () => {
    const [email, setEmail] = useState('')
    const [isLoading, setIsLoading] = useState(false); // New loading state
    const [isSuccess, setIsSuccess] = useState(false); // New success state

    const submitEmail = async () => {
        setIsLoading(true); // Start loading

        const data = JSON.stringify({
            "external_embed_id": "f327f2ce-ccf2-4221-a72e-398b77023f46",
            "publication_id": "bcbec892-ba03-4fec-824a-3661350e8f97",
            "email": email,
            "user_agent": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/120.0.0.0 Safari/537.36"
        });

        try {
            const response = await fetch('https://embeds.beehiiv.com/api/submit', {
                method: 'POST',
                headers: {
                    'authority': 'embeds.beehiiv.com',
                    'accept': 'application/json',
                    'accept-language': 'en-US,en;q=0.9,be;q=0.8,ar;q=0.7',
                    'cache-control': 'no-cache',
                    'content-type': 'application/json',
                    'cookie': '__cf_bm=.6gV0jbjUwv9xW66jpjJndWxq8WPkBjQwn96prZbWRA-1706318635-1-AeyAmYrKWN1Y4d8E2dj/vJba4oaqZGZMCZ4UPd2cq/zWzm+kNlrFTC69DIzOJoCsKkCNLbyzXLgkWdP2UMcc8JI=; cf_clearance=udIZNhGdqjoslhkC4vzUlNzW9NZp2ZaCKN.U5HHp2Aw-1706318636-1-AUOJ1YBoviUTsz7bTSdeXUYovyemw3ygA2lsH0Vh3dHs0uge6Qxo1VGbN2u2tUyeram0dPgoBid7Vza+hHC7pPQ=; __cf_bm=zEpZYY8jr_jPmy3T9yOiqGbLb2xMKX9PnRe0iEFqbYw-1706319187-1-AS02V8/qQtf25aAlgUMGkUhYxO+qenEyPFQe0FUyybp1Rv6NN8j14FrRswOeX3Kwfl+jp29nKQFaPD1z5SnsuA0=', 'dnt': '1',
                    'origin': 'https://embeds.beehiiv.com',
                    'pragma': 'no-cache',
                    'referer': 'https://embeds.beehiiv.com/f327f2ce-ccf2-4221-a72e-398b77023f46?slim=true',
                    'sec-ch-ua': '"Not_A Brand";v="8", "Chromium";v="120", "Google Chrome";v="120"',
                    'sec-ch-ua-mobile': '?1',
                    'sec-ch-ua-platform': '"Android"',
                    'sec-fetch-dest': 'empty',
                    'sec-fetch-mode': 'cors',
                    'sec-fetch-site': 'same-origin',
                    'user-agent': 'Mozilla/5.0 (Linux; Android 13; Pixel 7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/116.0.0.0 Mobile Safari/537.36'
                },
                body: data
            });



            const responseData = await response.json();
            console.log(JSON.stringify(responseData));

            //wait for 3 seconds 
            // await new Promise(resolve => setTimeout(resolve, 3000));
            setIsSuccess(true); // Set success state

        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false); // Stop loading regardless of success or error
        }
    };


    return (


        <div className="navbar fixed top-0 left-0 right-0 z-50 h-22 bg-transparent">

            <div className=" m-8 mx-auto h-16 bg-white rounded-[15px] flex justify-between items-center px-2.5" style={{ width: '90%', maxWidth: '1400px' }}>
                <a href="/" aria-current="page" aria-label="home" className="w-30 max-w-30 overflow-visible">
                    {/* Full logo for larger screens */}
                    <img src={tokcommentHeader} alt="Full logo" className="hidden md:inline-block align-middle  my-auto ml-5 w-52" />
                    {/* Logo only for smaller screens */}
                    <img src={tokcommentLogo} alt="Logo only" className="inline-block md:hidden align-middle  my-auto mr-5 w-8" />
                </a>


                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        submitEmail();
                    }}
                    style={{ width: '100%' }}>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }} >
                        <div className="relative w-full text-sm text-gray-900 bg-gray-50 ring-none outline-none rounded-l-lg" style={{ maxWidth: '360px' }}>

                            <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                                <EmailIcon />

                            </div>
                            <input className=" border-r-0 rounded-l-lg p-3 h-[40px] pl-10 w-full bg-transparent border border-inline  ring-none outline-none " placeholder="Free Weekly TikTok Trend Reports" type="email"
                                onChange={(e) => setEmail(e.target.value)}
                                value={email}
                                id="email" required="" />

                        </div>
                        <div className="ml-0">
                            <button type="submit"
                                className={` h-[40px] px-5 w-full text-sm font-medium text-center text-neutral cursor-pointer ${isSuccess ? 'bg-pink-200 ' : 'bg-violet-200 hover:bg-violet-300'} rounded-r-lg transition-all duration-350 ease-in-out ${isSuccess ? 'pr-6' : 'sm:pr-12'} ${isLoading ? 'opacity-50' : 'opacity-100'} ${isSuccess ? '' : "sm:bg-[url('https://assets-global.website-files.com/64d0ce443a2aa5bfebde64d2/64d9f3010b773a4d2194abfa_Arrow_Right_MD.svg')] sm:bg-no-repeat sm:bg-[right_1.5rem_center] sm:hover:bg-[90px]"} `}
                                disabled={isLoading || isSuccess}
                            >
                                {isSuccess ? 'Subscribed!' : 'Subscribe'}
                            </button>





                        </div>
                    </div>

                </form>
                {/* <iframe src="https://embeds.beehiiv.com/f327f2ce-ccf2-4221-a72e-398b77023f46?slim=true" style={{ margin: 0, borderRadius: '0px', backgroundColor: 'transparent', height: '52px', width: '100%', border: '0', overflow: 'hidden' }} frameBorder="0" scrolling="no" title="Beehiiv Embed" /> */}

            </div>

            <div className="w-nav-overlay w-full hidden absolute top-full left-0 right-0 overflow-hidden"></div>
        </div>

    )
}



export default Header