


const LogoWall = () => {


    return (
        <div className="flex justify-center flex-col bg-white pb-16 pt-12 mt-12">
            <p className="text-sm leading-6 text-gray-600 mb-2.5 mx-auto">Loved by 5,000+ brands &amp; agencies</p>

            <div className="w-layout-grid grid-11 image-grid">
                <img  src={require("../assets/images/logos/1-min.jpg")}  loading="lazy" />
                <img  src={require("../assets/images/logos/2-min.jpg")}  loading="lazy" />
                <img  src={require("../assets/images/logos/3-min.jpg")}  loading="lazy" />
                <img  src={require("../assets/images/logos/4-min.jpg")}  loading="lazy" />
                <img  src={require("../assets/images/logos/5-min.jpg")}  loading="lazy" />
                <img  src={require("../assets/images/logos/6-min.jpg")}  loading="lazy" />
                <img  src={require("../assets/images/logos/7-min.jpg")}  loading="lazy" />
                <img  src={require("../assets/images/logos/8-min.jpg")}  loading="lazy" />
                <img  src={require("../assets/images/logos/9-min.jpg")}  loading="lazy" />
                <img  src={require("../assets/images/logos/10-min.jpg")}  loading="lazy" />
                <img  src={require("../assets/images/logos/11-min.jpg")}  loading="lazy" />
                <img  src={require("../assets/images/logos/12-min.jpg")}  loading="lazy" />
            </div>

        </div>
    )
}

export default LogoWall