

const HeroSection = () => { 

    return (



        <div className="mx-auto mt-8 mb-6 text-center z-20 w-full  ">
    <h1 className="text-5xl sm:text-5xl md:text-5xl lg:text-6xl  font-base text-gray-800 leading-snug m-auto" style={{width:'90%', maxWidth:'1400px', fontWeight:'700'}}>
            TikTok Comment <span className="inline-block bg-gradient-to-r from-pink-500 to-blue-500 text-white px-3 py-2 rounded z-20">Generator</span>
        </h1>
    
        <h2 className="text-lg text-gray-700 lg:mt-4 lg:text-2xl mt-8 leading-relaxed m-auto " style={{width:'80%', maxWidth:'1200px'}}>
            Fill in the information below and download your comment image
        </h2>
    </div>
    

    )
}

export default HeroSection
{/*
 <h1 className="text-4xl sm:text-5xl md:text-6xl lg:text-5xl font-bold text-gray-800 leading-tight">
            TikTok Comment
            <span className="relative inline-block">
                <span className="absolute inset-0 transform mx-2 -my-2 -skew-y-3 h-14 bg-gradient-to-r from-pink-500 to-blue-500 rounded"></span>
                <span className="relative text-white px-0 mx-4  my-4 py-5 z-10">
                    Generator
                </span>
            </span>
        </h1> 
    */}