
import domtoimage from 'dom-to-image'; // Ensure you have this package installed


function downloadCommentImage() {

    var domNode = document.getElementById("fullResult");
    console.log({domNode})
    var scale = 10;
    domtoimage.toPng(domNode, {
        width: domNode.clientWidth * scale,
        height: domNode.clientHeight * scale,
        style: {
            transform: 'scale(' + scale + ')',
            transformOrigin: 'top left'
        }
    })
        .then(function (dataUrl) {
            domtoimage.toPng(domNode, {
                width: domNode.clientWidth * scale,
                height: domNode.clientHeight * scale,
                style: {
                    transform: 'scale(' + scale + ')',
                    transformOrigin: 'top left'
                }
            })
                .then(function (dataUrl2) {
                    domtoimage.toPng(domNode, {
                        width: domNode.clientWidth * scale,
                        height: domNode.clientHeight * scale,
                        style: {
                            transform: 'scale(' + scale + ')',
                            transformOrigin: 'top left'
                        }
                    })
                        .then(function (dataUrl3) {

                            var link = document.createElement('a');
                            link.download = "Comment.png";
                            link.href = dataUrl3;
                            link.click();
                        });
                });
        });
}

export default downloadCommentImage;